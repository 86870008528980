<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="类型">
          <el-select v-model="searchForm.type" placeholder="请选择类型" size="small" clearable>
            <el-option v-for="(item,i) in maintainTypeList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')" v-if="isAdmin">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="reportList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="conclusion" label="维护保养结论" show-overflow-tooltip width="140">
        </el-table-column>
        <el-table-column prop="type" label="维护类型" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            <span v-show="scope.row.type===310">个人维护</span>
            <span v-show="scope.row.type===311">外部单位维护</span>
          </template>
        </el-table-column>
        <el-table-column prop="month" label="月份" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.month|monthFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="fireAutoAlarm" label="火灾自动报警系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.fireAutoAlarm===306">正常运行</span>
            <span v-show="scope.row.fireAutoAlarm===307">故障运行</span>
            <span v-show="scope.row.fireAutoAlarm===308">瘫痪停用</span>
            <span v-show="scope.row.fireAutoAlarm===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="autoWaterFire" label="自动喷水灭火系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.autoWaterFire===306">正常运行</span>
            <span v-show="scope.row.autoWaterFire===307">故障运行</span>
            <span v-show="scope.row.autoWaterFire===308">瘫痪停用</span>
            <span v-show="scope.row.autoWaterFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="frothFire" label="泡沫灭火系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.frothFire===306">正常运行</span>
            <span v-show="scope.row.frothFire===307">故障运行</span>
            <span v-show="scope.row.frothFire===308">瘫痪停用</span>
            <span v-show="scope.row.frothFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="gasFire" label="气体灭火系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.gasFire===306">正常运行</span>
            <span v-show="scope.row.gasFire===307">故障运行</span>
            <span v-show="scope.row.gasFire===308">瘫痪停用</span>
            <span v-show="scope.row.gasFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="dryPowerFire" label="干粉灭火系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.dryPowerFire===306">正常运行</span>
            <span v-show="scope.row.dryPowerFire===307">故障运行</span>
            <span v-show="scope.row.dryPowerFire===308">瘫痪停用</span>
            <span v-show="scope.row.dryPowerFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="waterMistFire" label="细水雾灭火系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.waterMistFire===306">正常运行</span>
            <span v-show="scope.row.waterMistFire===307">故障运行</span>
            <span v-show="scope.row.waterMistFire===308">瘫痪停用</span>
            <span v-show="scope.row.waterMistFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="dischargeSmoke" label="排烟系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.dischargeSmoke===306">正常运行</span>
            <span v-show="scope.row.dischargeSmoke===307">故障运行</span>
            <span v-show="scope.row.dischargeSmoke===308">瘫痪停用</span>
            <span v-show="scope.row.dischargeSmoke===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="preventSmoke" label="防烟系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.preventSmoke===306">正常运行</span>
            <span v-show="scope.row.preventSmoke===307">故障运行</span>
            <span v-show="scope.row.preventSmoke===308">瘫痪停用</span>
            <span v-show="scope.row.preventSmoke===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="roomInsideWater" label="室内消防给水系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.roomInsideWater===306">正常运行</span>
            <span v-show="scope.row.roomInsideWater===307">故障运行</span>
            <span v-show="scope.row.roomInsideWater===308">瘫痪停用</span>
            <span v-show="scope.row.roomInsideWater===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="roomOutWater" label="室外消防给水系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.roomOutWater===306">正常运行</span>
            <span v-show="scope.row.roomOutWater===307">故障运行</span>
            <span v-show="scope.row.roomOutWater===308">瘫痪停用</span>
            <span v-show="scope.row.roomOutWater===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="light" label="疏散指示标志和应急照明" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.light===306">正常运行</span>
            <span v-show="scope.row.light===307">故障运行</span>
            <span v-show="scope.row.light===308">瘫痪停用</span>
            <span v-show="scope.row.light===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="electricity" label="消防供电配电" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.electricity===306">正常运行</span>
            <span v-show="scope.row.electricity===307">故障运行</span>
            <span v-show="scope.row.electricity===308">瘫痪停用</span>
            <span v-show="scope.row.electricity===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="fireBroadcast" label="火灾应急广播系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.fireBroadcast===306">正常运行</span>
            <span v-show="scope.row.fireBroadcast===307">故障运行</span>
            <span v-show="scope.row.fireBroadcast===308">瘫痪停用</span>
            <span v-show="scope.row.fireBroadcast===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="elevator" label="消防电梯" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.elevator===306">正常运行</span>
            <span v-show="scope.row.elevator===307">故障运行</span>
            <span v-show="scope.row.elevator===308">瘫痪停用</span>
            <span v-show="scope.row.elevator===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="other" label="其他系统" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.other===306">正常运行</span>
            <span v-show="scope.row.other===307">故障运行</span>
            <span v-show="scope.row.other===308">瘫痪停用</span>
            <span v-show="scope.row.other===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="preventFire" label="防火分隔" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.preventFire===306">正常运行</span>
            <span v-show="scope.row.preventFire===307">故障运行</span>
            <span v-show="scope.row.preventFire===308">瘫痪停用</span>
            <span v-show="scope.row.preventFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="cutFire" label="灭火器" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.cutFire===306">正常运行</span>
            <span v-show="scope.row.cutFire===307">故障运行</span>
            <span v-show="scope.row.cutFire===308">瘫痪停用</span>
            <span v-show="scope.row.cutFire===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceFunction" label="控制室联动控制设备功能" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.deviceFunction===306">正常运行</span>
            <span v-show="scope.row.deviceFunction===307">故障运行</span>
            <span v-show="scope.row.deviceFunction===308">瘫痪停用</span>
            <span v-show="scope.row.deviceFunction===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column prop="exits" label="消防通道" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            <span v-show="scope.row.exits===306">正常运行</span>
            <span v-show="scope.row.exits===307">故障运行</span>
            <span v-show="scope.row.exits===308">瘫痪停用</span>
            <span v-show="scope.row.exits===309">拆除</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="isAdmin">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="delReport(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看附件" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加':'修改'" :visible.sync="reportShow" width="55%" @close="clear" top="5%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="report" ref="reportForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="月份" prop="month" :label-width="formLabelWidth">
              <el-date-picker v-model="report.month" type="month" placeholder="选择月" size="small">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="火灾自动报警系统" prop="fireAutoAlarm" :label-width="formLabelWidth">
              <el-select v-model="report.fireAutoAlarm" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="自动喷水灭火系统" prop="autoWaterFire" :label-width="formLabelWidth">
              <el-select v-model="report.autoWaterFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="泡沫灭火系统" prop="frothFire" :label-width="formLabelWidth">
              <el-select v-model="report.frothFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="气体灭火系统" prop="gasFire" :label-width="formLabelWidth">
              <el-select v-model="report.gasFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="干粉灭火系统" prop="dryPowerFire" :label-width="formLabelWidth">
              <el-select v-model="report.dryPowerFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="细水雾灭火系统" prop="waterMistFire" :label-width="formLabelWidth">
              <el-select v-model="report.waterMistFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="排烟系统" prop="dischargeSmoke" :label-width="formLabelWidth">
              <el-select v-model="report.dischargeSmoke" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="防烟系统" prop="preventSmoke" :label-width="formLabelWidth">
              <el-select v-model="report.preventSmoke" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="室内消防给水系统" prop="roomInsideWater" :label-width="formLabelWidth">
              <el-select v-model="report.roomInsideWater" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="室外消防给水系统" prop="roomOutWater" :label-width="formLabelWidth">
              <el-select v-model="report.roomOutWater" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="疏散指示标志和应急照明" prop="light" :label-width="formLabelWidth">
              <el-select v-model="report.light" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防供电配电" prop="electricity" :label-width="formLabelWidth">
              <el-select v-model="report.electricity" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="火灾应急广播系统" prop="fireBroadcast" :label-width="formLabelWidth">
              <el-select v-model="report.fireBroadcast" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防电梯" prop="elevator" :label-width="formLabelWidth">
              <el-select v-model="report.elevator" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="其他系统" prop="other" :label-width="formLabelWidth">
              <el-select v-model="report.other" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="防火分隔" prop="preventFire" :label-width="formLabelWidth">
              <el-select v-model="report.preventFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="灭火器" prop="cutFire" :label-width="formLabelWidth">
              <el-select v-model="report.cutFire" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="控制室联动控制设备功能" prop="deviceFunction" :label-width="formLabelWidth">
              <el-select v-model="report.deviceFunction" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="消防通道" prop="exits" :label-width="formLabelWidth">
              <el-select v-model="report.exits" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in autoAlarmList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="维护类型" prop="type" :label-width="formLabelWidth">
              <el-select v-model="report.type" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in maintainTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="维护保养结论" prop="conclusion" :label-width="formLabelWidth">
              <el-input type="textarea" v-model="report.conclusion" autocomplete="off" placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="附件" :label-width="formLabelWidth">
              <el-upload class="upload-demo" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :disabled="uploadFlag" :on-success="uploadSuccess" :on-error="uploadError">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text"><em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="reportShow = false">取 消</el-button>
        <el-button type="primary" @click="addReport" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateReport" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%" v-dialogDrag :close-on-click-modal="false">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      token: '',
      enclosure: '',
      currentPage: 1,
      total: 0,
      formLabelWidth: '170px',
      reportList: [],
      maintainTypeList: [],
      autoAlarmList: [],
      report: {
        appendix: '',
        autoWaterFire: '',
        conclusion: '',
        cutFire: '',
        deviceFunction: '',
        dischargeSmoke: '',
        dryPowerFire: '',
        electricity: '',
        elevator: '',
        exits: '',
        fireAutoAlarm: '',
        fireBroadcast: '',
        frothFire: '',
        gasFire: '',
        light: '',
        month: '',
        other: '',
        preventFire: '',
        preventSmoke: '',
        roomInsideWater: '',
        roomOutWater: '',
        type: '',
        waterMistFire: ''
      },
      reportCopy: {},
      searchForm: {},
      optFlag: true,
      uploadFlag: false,
      reportShow: false,
      enclosureShow: false,
      loading: false,
      rules: {

      }
    }
  },
  created () {
    console.log(this.isAdmin)
    console.log(this.companyId)
    this.initTable()
    this.getMaintainType()
    this.getAutoAlarm()
    this.reportCopy = JSON.stringify(this.report)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.searchForm.type) {
        data.type = this.searchForm.type
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      this.loading = true
      const { data: result } = await this.$axios.get('/deviceProtectReport/list', { params: data })
      if (result.code === 200) {
        this.reportList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async delReport (id) {
      const confirm = await this.$confirm('此操作将永久删除该备案记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/deviceProtectReport/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateReport () {
      this.$refs.reportForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/deviceProtectReport/edit/${this.report.id}`, this.report)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.reportShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增培训
    async addReport () {
      this.$refs.reportForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/deviceProtectReport/add', this.report)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.reportShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.reportShow = true
          // this.$nextTick(() => {
          //   this.$refs.report.clearValidate()
          // })
          this.optFlag = true
          break
        case 'edit':
          this.reportShow = true
          this.optFlag = false
          // this.$nextTick(() => {
          //   this.$refs.report.clearValidate()
          // })
          this.report = JSON.parse(JSON.stringify(row))
          break
        case 'check':
          if (!row.appendix) {
            this.$message('此条信息无附件')
          } else {
            this.enclosure = row.appendix
            this.enclosureShow = true
          }
          break
      }
    },
    // 查找维护类型
    async getMaintainType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'deviceProtectType' } })
      if (result.code === 200) {
        this.maintainTypeList = result.data
      }
    },
    // 查找火灾自动报警系统
    async getAutoAlarm () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'deviceProtectStatus' } })
      if (result.code === 200) {
        this.autoAlarmList = result.data
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.report.appendix = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    // 关闭弹窗清空表单
    clear () {
      this.report = JSON.parse(this.reportCopy)
      this.report = {
        appendix: '',
        autoWaterFire: '',
        conclusion: '',
        cutFire: '',
        deviceFunction: '',
        dischargeSmoke: '',
        dryPowerFire: '',
        electricity: '',
        elevator: '',
        exits: '',
        fireAutoAlarm: '',
        fireBroadcast: '',
        frothFire: '',
        gasFire: '',
        light: '',
        month: '',
        other: '',
        preventFire: '',
        preventSmoke: '',
        roomInsideWater: '',
        roomOutWater: '',
        type: '',
        waterMistFire: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
</style>
